// Animation for bootstrap burger, when is active
.navbar-toggle {
  border: none;
  background: transparent !important;
  &:hover {
    background: transparent !important; }
  .icon-bar {
    width: 22px;
    transition: all 0.2s; }
  .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%; }
  .middle-bar {
    opacity: 0; }
  .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%; } }

//When the menu collapses, apply this css
.navbar-toggle.collapsed {
  .top-bar {
    transform: rotate(0); }
  .middle-bar {
    opacity: 1; }
  .bottom-bar {
    transform: rotate(0); } }



//Header block. It will have collapsed class if we're not in home.
header {
  position: relative;
  background-color: transparent;
  height: 64px;
  padding: 0 5%;
  .background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    img {
      position: absolute;
      left: 0;
      height: 288px;
      top: 0;
      width: 100%;
      @include breakpoint(768px) {
        height: 500px; } } }
  .overlay {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 288px;
    background-color: rgba(43, 51, 74, 0.6);
    @include breakpoint(768px) {
      height: 500px; } }

  &.collapsed {
    .background-wrapper {
      height: 80px;
      overflow: hidden;
      img {} }
    .overlay {
      height: 80px; } }
  @include breakpoint(768px) {
    padding: 0 5%;
    height: 80px; }
  nav.navbar {
    z-index: 3;
    height: 100%;
    .navbar-collapse {
      border-color: #2b334a;
      box-shadow: none;
      background-color: rgba(43, 51, 74, 0.9);
      right: 0;
      padding-top: 0.87em;
      position: absolute;
      width: 250px;
      @include breakpoint(768px) {
        width: auto;
        position: relative; }

      ul.navbar-right {
        padding-left: 32px;
        padding-bottom: 10px; }
      @include breakpoint(768px) {
        background-color: transparent;
        position: static;
        ul.navbar.right {
          padding: 0; } } }
    @include breakpoint(768px) {
      background-color: transparent;
      left: 0;
      height: 100%; }
    .navbar-header {
      margin-top: 15px;
      transition: 0.5s ease;
      .navbar-brand {
        padding: 0;
        @include breakpoint(768px) {
          padding-top: 0.75em; }
        img {
          height: 42px;
          width: 38px;
          @include breakpoint(768px) {
            height: auto;
            margin-top: 0;
            width: auto;
            position: static; } } }
      @include breakpoint(768px) {
        margin-top: 0;
        margin-left: -15px;
        margin-right: -15px; } } }


  h1 {
    font-weight: normal;
    margin: 0;
    color: red; }
  a {
    color: $white;
    text-transform: uppercase;
    &:hover {
      color: $white; } }
  .block-menu {
    position: relative;
    z-index: 2;
    padding: 1em 1em 1em 2em;
    float: left;
    span {
      display: inline-block;
      padding: 0 1em; }
    a {
      text-align: center;
      font-size: 0.938em; } }
  .block-right {
    float: right;
    position: relative;
    right: 0;
    z-index: 5;
    margin-right: 1em;
    font-size: 0.875rem;
    padding: 0.5em 0;

    .block-language {
      float: right;
      display: inline-block;
      height: auto;
      margin-left: 0;
      padding: 0.5em; }
    .logout {
      a {
        padding: 1.25em;
        position: absolute;
        right: 102px;
        top: 9px; } }
    .block-login {
      float: right;
      margin-right: 58px;
      span {
        color: $white; } } } }

.bg-color-white {
  .breadcrumb {
    padding: 10px 0 0;
    background-color: transparent;
    position: relative;
    right: 15px;
    height: 42px;
    font-size: 1em;
    display: block;
    a {
      background: none;
      text-transform: capitalize;
      padding: 0;
      color: $accent-color;
      &.active {
        color: $bluegrey_400;
        &::before {
          content: ""; } } } } }


.lang-sel {
  padding: 12px 0 12px 16px;
  margin-top: 5px;
  select {
    background: transparent;
    border: 1px solid $white;
    color: $white; } }
