.password-reset {
  margin: 0;
  .container {
    p {
      padding-bottom: 0.7em; } }
  form {

    .form-group {
      .pass-wrapper {
        position: relative;
        padding-bottom: 1em;
        display: inline-block; }
      .msg {
        left: 0;
        position: absolute;
        bottom: 0;
        font-size: 12px;
        width: 250px;
        text-align: left;
        &.ok {
          color: #29a156; }
        &.wrong {
          color: red; } } }
    input[type="submit"] {
      margin-top: 3em; }
    padding-bottom: 3em; } }
