////
/// normalize-libsass 1.0.3
/// MIT/GPLv2 License
/// lb.cm/normalize-libsass
////

/// Strict normalize
/// @type Boolean [false] - Set to true for strict normalize output.
$strict-normalize: false !default;

/// Font-size baseline
/// @type Length [16px]
$base-font-size: 16px !default;

/// Line-height baseline
/// @type Length [24px]
$base-line-height: 24px !default;

/// H1 headline size
/// @type Length [2 * 16px]
$h1-font-size: 2 * $base-font-size !default;

/// Rhythm unit
/// @type String [em] - Supported values: px, em, rem.
$rhythm-unit: 'em' !default;

/// Font-family baseline
/// @type Map [sans-serif]
$base-font-family: sans-serif !default;

/// Supported Browser versions
/// @type Map
$browser-min-version: (
  'chrome':  null,
  'firefox': null,
  'ie':      8,
  'safari':  6,
  'opera':   null
) !default;

// Assure no variables get overridden if strict is on
$normalize-base-font-size: if($strict-normalize, 16px, $base-font-size);
$normalize-base-line-height: if($strict-normalize, 24px, $base-line-height);
$normalize-h1-font-size: if($strict-normalize, 2 * $base-font-size, $h1-font-size);
$normalize-rhythm-unit: if($strict-normalize, 'em', $rhythm-unit);
$normalize-base-font-family: if($strict-normalize, sans-serif, $base-font-family);

/// Normalize Legacy Browser Support function
///
/// @requires {variable} browser
/// @requires {variable} version
///
/// @param {String} $browser - A valid browser name from $browser-minimum-versions.
/// @param {Number} $version - A browser version.
///
/// @return {Bool} - If browser is null or valid returns true.
@function normalize-browser-support($browser, $version) {
  // If browser is not null then check for support
  @if map-get($browser-min-version, $browser) {
    $supported-version: map-get($browser-min-version, $browser);
    // Pass if browser is supported or strict-normalize is on
    @if $strict-normalize or $supported-version <= $version {
      @return true;
    } @else {
      // Fail if browser is unsupported
      @return null;
    }
  }
  // Pass if browser is null
  @return true;
}

/// Convert one unit into another
/// @author Hugo Giraudel
///
/// @param {Number} $value - Initial value
/// @param {String} $unit - Desired unit
///
/// @return {Number}
/// @throw Error if `$unit` does not exist or if units are incompatible.
@function normalize-convert-unit($value, $unit) {
  // scss-lint:disable ZeroUnit
  $units: (
    'px': 0px,
    'cm': 0cm,
    'mm': 0mm,
    '%': 0%,
    'in': 0in,
    'em': 0em,
    'rem': 0rem,
    'pt': 0pt
  );
  // scss-lint:enable ZeroUnit

  @if map-has-key($units, $unit) {
    @return map-get($units, $unit) + $value;
  }

  @error "Unknown unit `#{$unit}`.";
}

/**
* 1. Set default font family to sans-serif.
* 2. Prevent iOS and IE text size adjust after device orientation
*    change, without disabling
*    user zoom.
*/
html {
  font-family: $normalize-base-font-family; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/**
* Remove default margin.
*/

body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */

/**
* Correct `block` display not defined for any HTML5 element in IE 8/9.
* Correct `block` display not defined for `details` or `summary` in IE 10/11
* and Firefox.
* Correct `block` display not defined for `main` in IE 11.
*/

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
* 1. Correct `inline-block` display not defined in IE 8/9.
* 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
*/

audio,
canvas,
progress,
video {
  @if normalize-browser-support('ie', 9) {
    display: inline-block; /* 1 */
  }
  vertical-align: baseline; /* 2 */
}

/**
* Prevent modern browsers from displaying `audio` without controls.
* Remove excess height in iOS 5 devices.
*/

audio:not([controls]) {
  display: none;
  height: 0;
}

@if normalize-browser-support('ie', 10) {
  /**
   * Address `[hidden]` styling not present in IE 8/9/10.
   */

  [hidden] {
    display: none;
  }
}

/**
* Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
*/

template {
  display: none;
}

/* Links
   ========================================================================== */

@if normalize-browser-support('ie', 10) {
  /**
   * Remove the gray background color from active links in IE 10.
   */

  a {
    background-color: transparent;
  }
}

/**
* Improve readability of focused elements when they are also in an
* active/hover state.
*/

a:active,
a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */

/**
* Address styling not present in IE 8/9/10/11, Safari, and Chrome.
*/

abbr[title] {
  border-bottom: 1px dotted;
}

/**
* Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
*/

b,
strong {
  font-weight: bold;
}

/**
* Address styling not present in Safari and Chrome.
*/

dfn {
  font-style: italic;
}

/**
* Address variable `h1` font-size and margin within `section` and `article`
* contexts in Firefox 4+, Safari, and Chrome.
*/

h1 {
  font-size: normalize-convert-unit(($normalize-h1-font-size / $normalize-base-font-size), $normalize-rhythm-unit);
  margin: normalize-convert-unit(($normalize-base-font-size / $normalize-base-line-height), $normalize-rhythm-unit) 0;
}

@if normalize-browser-support('ie', 9) {
  /**
   * Address styling not present in IE 8/9.
   */

  mark {
    background: #ff0;
    color: #000;
  }
}

/**
* Address inconsistent and variable font size in all browsers.
*/

small {
  font-size: 80%;
}

/**
* Prevent `sub` and `sup` affecting `line-height` in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */

@if normalize-browser-support('ie', 10) {
  /**
   * Remove border when inside `a` element in IE 8/9/10.
   */

  img {
    border: 0;
  }
}

@if normalize-browser-support('ie', 11) {
  /**
   * Correct overflow not hidden in IE 9/10/11.
   */

  svg:not(:root) {
    overflow: hidden;
  }
}

/* Grouping content
   ========================================================================== */

@if normalize-browser-support('ie', 9) or normalize-browser-support('safari', 6) {
  /**
   * Address margin not present in IE 8/9 and Safari.
   */

  figure {
    margin: 1em 40px;
  }
}

/**
* Address differences between Firefox and other browsers.
*/

hr {
  box-sizing: content-box;
  height: 0;
}

/**
* Contain overflow in all browsers.
*/

pre {
  overflow: auto;
}

/**
* Address odd `em`-unit font size rendering in all browsers.
*/

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */

/**
* Known limitation: by default, Chrome and Safari on OS X allow very limited
* styling of `select`, unless a `border` property is set.
*/

/**
* 1. Correct color not being inherited.
*    Known issue: affects color of disabled elements.
* 2. Correct font properties not being inherited.
* 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
*/

button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

@if normalize-browser-support('ie', 11) {
  /**
   * Address `overflow` set to `hidden` in IE 8/9/10/11.
   */

  button {
    overflow: visible;
  }
}

/**
* Address inconsistent `text-transform` inheritance for `button` and `select`.
* All other form control elements do not inherit `text-transform` values.
* Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
* Correct `select` style inheritance in Firefox.
*/

button,
select {
  text-transform: none;
}

/**
* 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
*    and `video` controls.
* 2. Correct inability to style clickable `input` types in iOS.
* 3. Improve usability and consistency of cursor style between image-type
*    `input` and others.
*/

button,
html input[type='button'], /* 1 */
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
* Re-set default cursor for disabled elements.
*/

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
* Remove inner padding and border in Firefox 4+.
*/

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
* Address Firefox 4+ setting `line-height` on `input` using `!important` in
* the UA stylesheet.
*/

input {
  line-height: normal;
}

@if normalize-browser-support('ie', 10) {
  /**
   * It's recommended that you don't attempt to style these elements.
   * Firefox's implementation doesn't respect box-sizing, padding, or width.
   *
   * 1. Address box sizing set to `content-box` in IE 8/9/10.
   * 2. Remove excess padding in IE 8/9/10.
   */

  input[type='checkbox'],
  input[type='radio'] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }
}

/**
* Fix the cursor style for Chrome's increment/decrement buttons. For certain
* `font-size` values of the `input`, it causes the cursor style of the
* decrement button to change from `default` to `text`.
*/

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
* 1. Address `appearance` set to `searchfield` in Safari and Chrome.
* 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
*/

input[type='search'] {
  box-sizing: content-box;
}

/**
* Remove inner padding and search cancel button in Safari and Chrome on OS X.
* Safari (but not Chrome) clips the cancel button when the search input has
* padding (and `textfield` appearance).
*/

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
* Define consistent border, margin, and padding.
*/

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
* 1. Correct `color` not being inherited in IE 8/9/10/11.
* 2. Remove padding so people aren't caught out if they zero out fieldsets.
*/

legend {
  @if normalize-browser-support('ie', 11) {
    border: 0; /* 1 */
  }
  padding: 0; /* 2 */
}

@if normalize-browser-support('ie', 11) {
  /**
   * Remove default vertical scrollbar in IE 8/9/10/11.
   */

  textarea {
    overflow: auto;
  }
}

/**
* Don't inherit the `font-weight` (applied by a rule above).
* NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
*/

optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */

/**
* Remove most spacing between table cells.
*/

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}
