.contact-wrapper {
  padding-bottom: 2em; }
.contact-main {
  padding: 0;
  h1 {
    margin-top: 0;
    font-size: 32px;
    padding-top: 0.67em;
    color: #2b334a;
    @include breakpoint(768px) {
      font-size: 64px; } } }
