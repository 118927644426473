// Font config declaration.

@font-face {
  font-family: 'jaapokki_subtractregular';
  src: url('../src/fonts/jaapokkisubtract-regular-webfont.eot');
  src: url('../src/fonts/jaapokkisubtract-regular-webfont.eot?#iefix') format("embedded-opentype"), url('../src/fonts/jaapokkisubtract-regular-webfont.woff2') format("woff2"), url('../src/fonts/jaapokkisubtract-regular-webfont.woff') format("woff"), url('../src/fonts/jaapokkisubtract-regular-webfont.ttf') format("truetype"), url('../src/fonts/jaapokkisubtract-regular-webfont.svg#jaapokki_subtractregular') format("svg");
  font-weight: normal;
  font-style: normal; }

@import url('https://fonts.googleapis.com/css?family=Raleway:400,500,700,900');

html {
  font-size: 16px; }

body {
  color: $color-body;
  font-family: $django_main;
  font-weight: 400;
  line-height: 1.3;
  font-variant-numeric: lining-nums; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-h;
  font-family: $django_main;
  font-weight: 900; }

h1 {
  line-height: 0.8;
  margin: 0.77em 0;
  font-size: 4em;
  line-height: 72px; }

h2 {
  font-size: 3.5em; }

h3 {
  font-size: 3em; }

h4 {
  font-size: 2em; }

h5 {
  font-size: 1.5em; }

p {
  line-height: 1.5em;
  margin-bottom: 1.5em;
  &.highlight {
    font-weight: 600; }
  &.smalltext {
    font-size: 0.875em;
    line-height: 1.71;
    font-weight: normal; }
  &.smallgray {
    color: $bluegrey_400; }
  &.extrasmallgray {
    color: $bluegrey_400;
    font-size: 0.75em; } }
a {
  color: $color-a;
  text-decoration: none;

  &:hover {
    color: $color-a-hover;
    text-decoration: none; } }
