// Typography
$title: 'Advent Pro';
$content: 'Open Sans';
$django_main: 'Raleway', 'Arial', sans-serif;

// Colours

$white: hsl(0, 0%, 100%);
$blue: hsl(225, 27%, 23%);
$dark-blue: hsl(225, 42%, 29%);  //Cambiado
$gray: hsl(225, 4%, 38%);
$medium-gray: hsl(198, 6%, 86%);  //Nuevo
$accent-color: #D81B60; //Nuevo

$color: $gray;
$dark-color: hsl(16, 18%, 84%);
$shadow: hsl(0, 0%, 53%);

// Bedjango colours:

$django_green: #29A156;


$bluegrey_900: #2B334A;
$bluegrey_400: #78909C;
$bluegrey_200: #B0BEC5;
$bluegrey_100: #CFD8DC;
$bluegrey_50: #ECEFF1;

$error: #F4511E;
$warning: #FFB300;



// Font - Color
$color-body: $color;
$color-h: $blue;
$color-a: $dark-blue;
$color-a-hover: $blue;

// Screen sizes (grid)

// default
$x-small: 22.5em;
$small: 30em;

// > 704
$medium: 44em;

// > 1024
$large: 64em;

// > 1200
$x-large: 75em;
$xx-large: 82em;
$monster: 118.75em;

// Legacy support
$legacy-support-for-ie6: false;
$legacy-support-for-ie7: false;
$legacy-support-for-ie8: true;
