.registration-layout {
  text-align: left;
  @include breakpoint(768px) {
    padding-bottom: 80px; }
  .registration-form-wrapper {
    .registration {
      padding: 0;
      margin-bottom: 0;
      h1 {
        position: static;
        text-align: left;
        margin-top: 0;
        padding-left: 6%;
        padding-top: 0.67em;
        padding-right: 0;
        font-size: 32px;
        @include breakpoint(768px) {
          font-size: 48px; }
        @include breakpoint(890px) {
          font-size: 64px; } } }
    .create-account-form {
      @include breakpoint(768px) {
        margin-top: 0;
        margin-left: 6%;
        position: relative; }
      form {
        background-color: white;
        @include breakpoint(768px) {
          width: 842px;
          height: 335px;
          @include display(flex);
          @include justify-content(flex-start); }
        .image-separator {
          width: 40%;
          margin-right: 40px;
          display: inline-block;
          vertical-align: top;
          img {
            width: 100%; } }

        .registration-form {
          padding-top: 36px;
          @include breakpoint(768px) {
            display: inline-block;
            width: 54%; }

          .action-call {
            padding-bottom: 2em;
            @include breakpoint(768px) {
              padding-bottom: 0; }
            span {
              display: block;
              font-size: 12px;
              color: $bluegrey_400;
              border-left: 2px solid $bluegrey_400;
              padding: 0 3%;
              margin-bottom: 29px;
              margin-top: 20px;
              margin-left: 4%;
              @include breakpoint(768px) {
                padding-left: 0 10px 0 0;
                padding-right: 0;
                margin-left: 0; } }

            button {
              @include button-maker(40px, 10px);
              border-radius: 2px;
              margin: 0 auto 1em auto;
              display: block;
              @include breakpoint(768px) {
                margin: 0 auto; } } }

          .fields-separator {
            @include breakpoint(768px) {
              @include display(flex);
              flex-wrap: wrap;
              justify-content: space-between; }

            .form-group {
              width: 100%;
              margin-left: 7%;
              margin-right: 7%;
              position: relative;
              @include breakpoint(768px) {
                width: 50%;
                padding: 0;
                margin-left: 0;
                margin-right: 0; }
              label {
                display: block;
                font-size: 14px;
                font-weight: 600;
                color: #2b334a; }

              .msg {
                position: absolute;
                bottom: -16px;
                left: 0;
                font-size: 12px;
                &.ok {
                  color: #29a156; }
                &.wrong {
                  color: red; } }

              input {
                height: 40px;
                width: 100%;
                padding-left: 12px;
                border-radius: 2px;
                background-color: #ffffff;
                border: solid 1px #cfd8dc;
                @include breakpoint(768px) {
                  width: 188px; } } } } } } } } }
