.list-with-badges {
  text-align: left;
  counter-reset: li;
  li {
    position: relative;
    padding-left: 4em;
    .badge {
      font-family: "Raleway";
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      background-color: #29a156; } }
  li::before {
    counter-increment: li;
    content: counter(li);
    position: absolute;
    left: 16px;
    color: #78909c; }

  li:nth-last-child(4n+1) {
    span {
      background-color: #ffb300; } }
  li:nth-last-child(4n+2) {
    span {
      background-color: #f4511e; } }
  li:nth-last-child(4n+3) {
    span {
      background-color: #78909c; } } }


.django-list {
  text-align: left;
  a {
    h4 {
      font-size: 14px;
      color: #78909c !important;
      font-weight: bold !important;
      text-transform: uppercase;
      font-family : "Raleway" !important;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      line-height: normal;
      margin-top: 0.4em;
      margin-bottom: 0.7em; }
    p {
      font-size: 14px; } } }
