.errorlist {
  background-color: rgba(243, 80, 30, 0.2);
  border-radius: 2px;
  list-style: none;
  padding: 1em 0 1em 3.6em;
  position: relative;
  &::after {
   position: absolute;
   content: "ERROR";
   top: 15.5px;
   left: 5px;
   color: #cd4419;
   font-weight: bold;
   font-size: 12px; } }
