.navbar-custom {
  background: transparent;
  border: none;
  margin-bottom: 0;
  a {
    font-weight: 700;
    font-size: 0.9375rem; }
  .navbar-text {
    color: $white;
    margin-top: 20px; }

  .navbar-nav {
    > li > a {
      color: $white;
      padding: 11.5px 31px;
      @include breakpoint(768px) {
        padding: 18px 12px 24px; }

      &:hover, &:focus {
        color: $white; }

      &.btn-ghost {
        background: transparent;
        border: 1px solid $white;
        display: inline-block;
        padding: 9px 24px;
        font-size: 0.8125rem;
        margin-top: 8px;
        &:hover {
          background: $accent-color;
          border-color: $accent-color; } } }

    > .active > a {
      &, &:hover, &:focus {
        color: $white;
        background-color: transparent;
        border-left: 6px solid $accent-color;
        padding-left: 24px;
        @include breakpoint(768px) {
          color: $white;
          background-color: transparent;
          border-bottom: 6px solid $accent-color;
          border-left: none; } } } }


  .navbar-toggle {
    border: none;
    .icon-bar {
      background-color: $white; }
    &:hover, &:focus {
      background-color: $accent-color; } } }

.btn-registro {
  text-transform: none;
  font-size: 0.875rem;
  text-decoration: underline; }

.btn-ghost {
  background: transparent;
  border: 1px solid $white;
  padding: 6px 12px; }

@media (min-width: 992px) {
  .navbar-custom .navbar-nav > li > a {
    padding: 18px 15px 24px; } }
