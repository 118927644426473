//Background for base template without the header.

.no-header {
  background-color: $bluegrey_400;
  background-image: url('../images/logo.png');
  background-repeat: no-repeat;
  background-position: 10% 10%;
  height: 100%; }

.registered {
  text-align: center;

  a {
    margin-top: 2em;
    margin-bottom: 4em; } }
