.blog-content {
  .blog-image {
    position: absolute;
    max-height: 450px;
    top: 0;
    left: 0;
    width: 100%; }
  .blog-title {
    z-index: 999;
    position: relative;
    h1 {
      color: white;
      text-align: center; } }
  .author {
    z-index: 999;
    position: relative;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50px;
      display: block;
      border: none;
      margin: 0 auto; }

    span {
      color: white;
      display: block;
      margin: 0 auto;
      font-size: 14px; } }

  .blog-article {
    max-width: 810px;
    position: relative;
    margin: 200px auto 0 auto;
    padding-bottom: 2em;
    p {
      line-height: 2;
      font-size: 16px;
      letter-spacing: normal;
      text-align: left;
      font-style: normal;
      margin-bottom: 0;
      font-stretch: normal;
      .intro-letter {
        font-size: 96px;
        padding-right: 10px;
        color: $django_green;
        letter-spacing: normal;
        font-style: normal;
        font-stretch: normal;
        float: left;
        width: auto;
        line-height: 92px; } } } }
