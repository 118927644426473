.bootstrap-table {
  .fixed-table-container {
    border: 0;
    .fixed-table-pagination {
      .pull-right {
        .pagination {
          li {
            &.active {
              a {
                border-bottom: 1px solid #2b334a; } }
            a {
              border: 0;
              padding: 0;
              background-color: transparent;
              margin: 0.5em 0.3em;
              color: #2b334a; } } } } }
    .fixed-table-body {
      border: 1px solid #ddd;
      table {
        tbody {
          tr {
            background-color: #fff;
            &:hover {
              background-color: $bluegrey_50; } }
          td {
            border-left: 0; } } } } } }
.django-panel {
  text-align: left;
  .panel-heading {
    h2 {
      font-family: "Raleway";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal; }
    .panel-body {
      p {
        font-size: 14px;
        line-height: 1.71;
        font-weight: normal;
        letter-spacing: normal;
        font-style: normal; } } } }
